<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Level Name"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Level Name") }}
                </label>
                <multiselect
                  v-model="form.levelName"
                  :key="form.levelName"
                  :options="[
                    'warning level 1',
                    'warning level 2',
                    'warning level 3',
                  ]"
                  :multiple="false"
                >
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  :label="$t('Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Document Template"
                rules="required"
              >
                <MultiSelectInput
                  :required="true"
                  v-model="form.documentTemplateId"
                  :options="documentServices.data"
                  :key="form.documentTemplateId"
                  :multiple="false"
                  :textLabel="$t('Document Template')"
                  label="name"
                  trackBy="id"
                  moduleName="documentService"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Period Days"
                rules="required"
              >
                <text-input
                  :type="`number`"
                  :required="true"
                  v-model="form.periodDays"
                  :error="errors.periodDays"
                  :label="$t('Period Days')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Reminder Fee"
                rules="required"
              >
                <text-input
                  :type="`number`"
                  :required="true"
                  v-model="form.reminderFee"
                  :error="errors.reminderFee"
                  :label="$t('Reminder Fee')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-12">
              <validation-provider
                #default="{ errors }"
                name="Start Text"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Start Text") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="form.startText"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-12">
              <validation-provider
                #default="{ errors }"
                name="End Text"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("End Text") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="form.endText"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-12">
              <validation-provider
                #default="{ errors }"
                name="Mail Text"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Mail Text") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="form.mailText"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="createReminderLevel"
          class="d-flex align-items-center gap-1"
          v-if="$can(`${$route.meta.permission}.create`)"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Create Reminder Level") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    TextInput,
    PageHeader,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    MultiSelectInput,
  },
  computed: {
    ...mapGetters("documentService", ["documentServices", "count"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Reminder Levels"),
          to: "/invoice-reminder-levels",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return (
        !this.form.levelName ||
        !this.form.periodDays ||
        !this.form.reminderFee ||
        !this.form.startText ||
        !this.form.endText ||
        !this.form.mailText
      );
    },
  },
  data() {
    return {
      form: {
        levelName: "",
        periodDays: "",
        reminderFee: "",
        startText: "",
        endText: "",
        mailText: "",
        name: "",
        documentTemplateId: "",
      },
    };
  },
  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("documentService/list", {
        limit_start: 0,
        limit_count: 25,
      });
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    async createReminderLevel() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            this.$store.commit("showLoader", true);
            await this.$store.dispatch("invoiceReminderLevel/create", {
              ...this.form,
              documentTemplateId: this.form.documentTemplateId?.id ?? "",
            });
            this.$router.push("/invoice-reminder-levels");
          } catch (e) {
            console.error("api error ___", e);
          } finally {
            this.$store.commit("showLoader", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
